<template>
  <div :class="` mb-4  text-sm`">
    <div style="flex: 1">
      <div
        class="label font-weight-bold text-gray-800 mb-2"
        v-if="outlined && label"
      >
        {{ label }}
      </div>
      <div
        class="d-flex"
        :class="`${focusClass} ${
          !outlined
            ? 'border-top-0 border-left-0 border-right-0 rounded-0'
            : ' rounded border border-black'
        } mt-0 py-0 bg-transparent align-items-center`"
      >
        <div v-if="preppendIconInner">
          <b-icon :icon="preppendIconInner"></b-icon>
        </div>
        <b-form-file
          class="text-black text-sm-2 bg-transparent border-0"
          style="height: 2.3rem; font-size: 0.9rem"
          :placeholder="!outlined ? label : placeholder"
          @focus="isFocused = true"
          @blur="isFocused = false"
          :type="type"
          v-bind="$attrs"
          v-model="formFile"
          v-on="$listeners"
        />
        <slot name="append" />
        <div v-if="appendIconInner">
          <b-icon :icon="appendIconInner"></b-icon>
        </div>
      </div>
      <slot name="errors" />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: true,

  props: {
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    color: {
      type: String,
      default: "grey",
    },
    file: {
      type: File,
    },
    appendIconInner: {
      type: Boolean,
      default: false,
    },
    preppendIconInner: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formFile: this.file,
      isFocused: false,
    };
  },
  computed: {
    focusClass() {
      let result = `border-gray-700 text-gray-700`;
      if (this.isFocused) result = `border-gray-800 text-black`;
      return result;
    },
    textClass() {
      let result = `text-gray-700`;
      if (this.isFocused) result = `text-gray-800`;
      return result;
    },
  },
  watch: {
    formFile(v) {
      this.$emit("input", v);
      this.formFile = v;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-box__textfield {
  outline: none;
  border: none;
  // height: 2.3rem;
  // background-color: green;
}
</style>
