import Vue from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

//add global styles
import "./assets/scss/main.scss";

//plugins
import "./plugins/bootstrap-vue.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
Vue.use(Loading, {
  loader: "bars",
  opacity: 0.95,
  color: "#2250B9",
});

// add date plugin
import VueLuxon from "vue-luxon";
Vue.use(VueLuxon);

//Form validator
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

//Import Default Layouts
import Private from "./layouts/Private.vue";
import Public from "./layouts/Public.vue";
Vue.component("private", Private);
Vue.component("public", Public);

//add http client
import { http } from "./http";
Vue.use(http);

//add charts
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

//add papaparse for csv exports
import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);

// add currency formatter
import currencyFormatter from "./plugins/formatCurrency";
Vue.use(currencyFormatter);

// Toast alert setup
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
  // You can set your default options here
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};
Vue.use(Toast, options);

// google analytics
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
});

const requireComponent = require.context(
  "./components/global",
  true,
  /\.(js|vue)$/i
);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});
