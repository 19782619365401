import Vue from "vue";
import { resolveRequestError } from "@/utils/errorsHandler";
import * as ENDPOINTS from "./endpoints";

export default class RequestsService {
  static async fetchRequests() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.GET_REQUESTS);

      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }
}
