var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-wrapper"},[_c('button',_vm._g({ref:"baseButton",staticClass:"py-2 btn px-4 rounded",class:{
      'bg-transparent outline': _vm.outline,
      'btn-lg': _vm.large,
      'btn-sm': _vm.small,
      'btn-md': _vm.medium,
      'btn-block': _vm.full,
      'btn-shadow': _vm.bgShadow,
      'py-2 btn-pad': _vm.pad,
      [`bg-${_vm.bgColor}`]: _vm.bgColor,
      [`text-${_vm.textColor}`]: _vm.textColor,
      'btn-pad': _vm.pad,
    },attrs:{"disabled":_vm.disableButton}},_vm.$listeners),[_c('div',{staticClass:"d-flex align-items-center justify-content-center text-capitalize",class:{ 'text-sm': true }},[_vm._t("default"),_vm._t("preppend-icon"),_vm._v(" "+_vm._s(_vm.text)+" "),_vm._t("post-icon"),(_vm.loading)?_c('b-spinner',{staticClass:"ml-3",attrs:{"small":"","variant":"light"}}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }