<template>
  <div class="sidebar bg-primary--gradient pl-5 pr-5 py-5">
    <div class="mb-3">
      <rm-base-icon name="oneapp-logo" />
    </div>
    <div class="mt-4 mb-5" v-for="(navItem, ix) in navItems" :key="ix">
      <div class="text-white mb-2 pb-1 text-sm">{{ navItem.name }}</div>
      <div
        class="d-flex align-items-center mb-3 pointer"
        @click="globalRouter(item.route)"
        v-for="(item, idx) in navItem.items"
        :key="idx"
      >
        <div class="mr-4">
          <rm-base-icon :name="item.icon" />
        </div>

        <div class="text-white text-sm nav-item__text">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    navItems: [
      {
        name: "MAIN",
        items: [
          // {
          // 	icon: "dashboard",
          // 	route: "dashboard",
          // 	name: "Dashboard",
          // },
          {
          	icon: "client",
          	route: "clients",
          	name: "Clients",
          },
          {
          	icon: "realtors",
          	route: "realtors",
          	name: "Realtors",
          },
          {
            icon: "gps",
            route: "locations",
            name: "Locations",
          },
          {
            icon: "discover",
            route: "properties",
            name: "Properties",
          },
          // {
          // 	icon: "pending-allocations",
          // 	route: "pending-allocations",
          // 	name: "Pending Allocations",
          // },
          // {
          // 	icon: "training",
          // 	route: "training",
          // 	name: "Training",
          // },
        ],
      },
      {
      	name: "FINANCES",
      	items: [
      		{
      			icon: "sales",
      			route: "sales",
      			name: "Sales",
      		},
      		// {
      		// 	icon: "promotions",
      		// 	route: "promotions",
      		// 	name: "Promotions",
      		// },
      		{
      			icon: "moneys",
      			route: "payments",
      			name: "Payments",
      		},
          {
            icon: "moneys",
            route: "payouts",
            name: "Payout"
          },
      		{
      			icon: "commissions",
      			route: "commissions",
      			name: "Commissions",
      		},
      		// {
      		// 	icon: "withholding-tax",
      		// 	route: "withholding-tax",
      		// 	name: "Withholding Tax",
      		// },
      		// {
      		// 	icon: "discount-vouchers",
      		// 	route: "discount-vouchers",
      		// 	name: "Discount Vouchers",
      		// },
      	],
      },
      // {
      // 	name: "SETUP",
      // 	items: [
      // 		{
      // 			icon: "all-requests",
      // 			route: "all-requests",
      // 			name: "All Requests",
      // 		},
      // 		{
      // 			icon: "settings",
      // 			route: "settings",
      // 			name: "Settings",
      // 		},
      // 		{
      // 			icon: "users",
      // 			route: "users",
      // 			name: "Users",
      // 		},
      // 	],
      // },
      {
        name: "SETTINGS",
        items: [
          {
            icon: "settings",
            route: "change-password",
            name: "Change Password",
          },
        ]
      }
    ],
  }),
};
</script>

<style lang="scss" scoped>
.nav-item__text {
  color: #e0e0e0;
}

.sidebar {
  height: 100vh;
  width: 300px;
  min-width: 300px;
  overflow-y: scroll;
}
</style>
