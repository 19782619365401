import LocationService from '../../services/locations';

const state = {
  locations: [],
  locationsList: [],
  location: {},
};

const getters = {
  locationsList: ({ locationsList }) => locationsList,
  location: ({ location }) => location,
};

const mutations = {
  setLocationsList(state, data) {
    state.locationsList = [ ...data ];
  },
  setLocation(state, data) {
    state.location = { ...data };
  },
};

const actions = {
  async createLocation({ dispatch }, form) {
    let { data, message, status } = await LocationService.createLocation(form);
    dispatch(
      'toast',
      {
        variant: status ? 'success' : 'error',
        message,
      },
      { root: true }
    );

    return data;
  },

  async createProperty({ dispatch }, form) {
    let { data, message, status } = await LocationService.createProperty(form);
    dispatch(
      'toast',
      {
        variant: status ? 'success' : 'error',
        message,
      },
      { root: true }
    );

    return data;
  },

  async fetchProductTypes() {
    const data = await LocationService.fetchProductTypes();
    return data;
  },

  async fetchLocations(_, params) {
    let data = await LocationService.fetchLocations({
      limit: params.limit || 20,
      page: params.page || 1,
    });
    return data;
  },

  async fetchLocationsList({ commit }) {
    const data = await LocationService.fetchLocationsList();
    if (data) {
      commit("setLocationsList", data.payload || []);
    }
    return true;
  },

  async fetchLocationById({ commit }, form = {}) {
    const { id } = { ...form };
    const data = await LocationService.fetchLocationById(id);
    if (data) {
      commit("setLocation", data.payload || {});
    }
    return true;
  },

  async fetchProperties(_, params) {
    const data = await LocationService.fetchProperties({
      page: params.page,
      limit: params.limit || 5,
      offset: params.offset || 0,
    });
    return data;
  },

  async fetchPropertyById(_, form) {
    let { propertyId } = form;

    const data = await LocationService.fetchPropertyById(propertyId);
    return data;
  },

  async fetchPropertiesInLocation(_, form) {
    const { data } = await LocationService.fetchPropertiesInLocation(form);

    return data;
  },

  async editPropertyById(_, form) {
    let propertyId = form.id;

    const { data } = await LocationService.editPropertyById(propertyId, form);
    // console.log('from the location module', data);
    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
