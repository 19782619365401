<template>
  <div class="base-modal-container">
    <b-modal
      :id="`modal-lg-${id}`"
      :ref="`modal-lg-${id}`"
      :size="size"
      hide-footer
      hide-header
      no-close-on-backdrop
      centered
      dialog-class="modal-dialog"
      modal-class="base-modal"
    >
      <template #modal-header="{ close }">
        <div class="d-flex py-3">
          <h5 class="mb-0" v-if="title">{{ title }}</h5>
        </div>

        <b-button v-if="closeBtn" variant="dark" @click="close()">
          &times;
        </b-button>
      </template>
      <div class="px-lg-5 px-3 pb-4 pt-5 me">
        <slot />
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    size: {
      type: String,
      default: 'lg',
    },
    title: String,
    backArrow: Boolean,
    closeBtn: Boolean,
  },
  mounted() {},
};
</script>

<style lang="scss">
.base-modal + .modal-backdrop {
  background: rgba(212, 212, 212, 0.61);
  backdrop-filter: blur(8px);
  left: 0;
  @media screen and (max-width: 768px) {
    left: 0;
  }
}

.modal-dialog {
  .modal-header {
    border-bottom: none;
    align-items: center;
    padding: 1rem 3rem !important;
    @media screen and (max-width: 768px) {
      padding: 1rem 1.5rem !important;
    }
  }

  .modal-body {
    padding: 0;
  }

  .modal-content {
    border: none;
  }
}
</style>
