import Vue from 'vue';
import { resolveRequestError } from '@/utils/errorsHandler';
import * as ENDPOINTS from './endpoints';

export default class PaymentService {
  static async fetchPayments(params) {
    try {
      const { data } = await Vue.http.get(
        ENDPOINTS.PAYMENTS,
        { params }
      );
      return data.payload;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchPendingPayments(params) {
    try {
      const { data } = await Vue.http.get(
        ENDPOINTS.PENDING_PAYMENTS,
        { params }
      );
      return data.payload;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchPaymentById(id) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.GET_PAYMENT(id));
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async verifyPayment(form) {
    try {
      const data = await Vue.http.post(ENDPOINTS.VERIFY_PAYMENT, form);

      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async approvePayment(form) {
    try {
      const data = await Vue.http.post(ENDPOINTS.APPROVE_PAYMENT, form);

      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async declinePayment(form) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.DECLINE_PAYMENT, form);
      return data;
    } catch (err) {
      resolveRequestError(err); 
    }
  }

  static async fetchPaymentCount() {
    try {
      const data = await Vue.http.get(ENDPOINTS.GET_PAYMENT_COUNT);
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchPendingPaymentCount() {
    try {
      const data = await Vue.http.get(ENDPOINTS.GET_PENDING_PAYMENT_COUNT);
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }
}
