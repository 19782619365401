import { ClientsService } from '../../services';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchCustomers(_, params) {
    const { payload } = await ClientsService.fetchCustomers(params);
    return payload;
  },

  async fetchUserById(_, id) {
    const { payload } = await ClientsService.fetchUserById(id);
    return payload;
  },

  // async createUser(_, credentials) {
  //   let { data } = await ClientsService.createNewUser(credentials);

  //   return data;
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
