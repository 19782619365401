import Vue from 'vue';
import {resolveRequestError} from '@/utils/errorsHandler';
import * as ENDPOINTS from './endpoints';

export default class PayoutService {
    static async fetchPayouts(params) {
        try {
            const {data} = await Vue.http.get(
                ENDPOINTS.PAYOUT,
                { params}
            );
            return data.payload;
        } catch (err) {
            resolveRequestError(err)
        }
    }
    static async createPayout(form){
        try {
            const data = await Vue.http.post(
                ENDPOINTS.PAYOUT,
                form
            )
            return data;
        } catch (err) {
            resolveRequestError(err)
        }
    }
    static async approvePayout(params){
        try {
            const data = await Vue.http.post(ENDPOINTS.APPROVE_PAYOUTS, params);
            return data;
        } catch (err){
            resolveRequestError(err)
        }
    }
}