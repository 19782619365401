<template>
  <div class="py-3">
    <b-form-datepicker
      id="datepicker-placeholder"
      placeholder="Choose a date"
      locale="en"
    ></b-form-datepicker>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped></style>
