import Vue from 'vue';
import App from './App.vue';
import { router } from './http';
import store from './store';
import './includes';

let getLocal = localStorage.getItem('authToken');

if (getLocal) {
  Vue.http.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('authToken')}`;

  store.dispatch('auth/fetchProfile');
}

Vue.config.productionTip = false;

let app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default app;
