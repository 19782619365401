import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth.module';
import clients from './modules/clients.module';
import realtors from './modules/realtors.module';
import locations from './modules/locations.module';
import sales from './modules/sales.module';
import admin from './modules/admin.module';
import requests from './modules/requests.module';
import payments from './modules/payment.module';
import commissions from './modules/commission.module';
import payouts from './modules/payout.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {
    toast(_, data) {
      let variant = data.variant;
      if (data.message) {
        this._vm.$toast[variant](data.message);
      }
    },
  },
  modules: {
    auth,
    clients,
    realtors,
    locations,
    sales,
    admin,
    requests,
    payments,
    commissions,
    payouts
  },
});
