import axios from "axios";
import Vue from "vue";
import router from "./router";

export const http = {
  install(Vue) {
    Vue.prototype.$http = Vue.http = axios.create({
      baseURL: process.env.VUE_APP_API_BASE || 'https://one-app-backup.herokuapp.com/api',
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export const RouterPlugin = {
  install(Vue) {
    Vue.prototype.globalRouter = async function (route, params, query) {
      await router.push({ name: route, params: params, query: query })
      .catch(() => {});
    };
  },
};

Vue.use(RouterPlugin);

export { router };
