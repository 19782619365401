import Vue from 'vue';
import { resolveRequestError } from '@/utils/errorsHandler';
import * as ENDPOINTS from './endpoints';

export default class SalesService {
  static async fetchSales(params) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.SALES, { params });
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchSalesById(id) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.GET_SALES_BY_ID(id));
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async createSubscriptionPayment(payload) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.CREATE_SUBSCRIPTION_PAYMENT,
        { ...payload }
      );
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  // static async fetchPaymentEvents() {
  //   try {
  //     const { data } = await Vue.http.get(ENDPOINTS.GET_PAYMENT_EVENTS);

  //     return data;
  //   } catch (err) {
  //     resolveRequestError(err);
  //   }
  // }
}
