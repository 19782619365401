import Vue from "vue";
import { resolveRequestError } from "@/utils/errorsHandler";
import * as ENDPOINTS from "./endpoints";

export default class AdminService {
  static async fetchAllAdmin() {
    try {
      const { data } = await Vue.http.get(`${ENDPOINTS.CLIENTS}?is_admin=True`);

      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchWalletHistory(params) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.GET_WALLET_HISTORY, {
        params,
      });
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async createAdmin(form) {
    try {
      const { data } = await Vue.http.post(`${ENDPOINTS.CREATE_ADMIN}`, form);

      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }
}
