import Vue from 'vue';
import { resolveRequestError } from '@/utils/errorsHandler';
import * as ENDPOINTS from './endpoints';

export default class LocationService {
  static async fetchLocations({ limit, page }) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.GET_LOCATIONS(limit, page));
      return data.payload;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchLocationById(id) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.GET_LOCATION_BY_ID(id));
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async createLocation(form) {
    try {
      const data = await Vue.http.post(ENDPOINTS.CREATE_LOCATION, form);
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchLocationsList() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.GET_LOCATION_LIST);
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async createProperty(form) {
    try {
      const data = await Vue.http.post(ENDPOINTS.CREATE_PROPERTY, form);
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchProductTypes() {
    try {
      const data = await Vue.http.get(ENDPOINTS.GET_PRODUCT_TYPES);
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchProperties({ page, limit, offset }) {
    try {
      const data = await Vue.http.get(
        ENDPOINTS.FETCH_PROPERTIES(page, limit, offset)
      );
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchPropertiesInLocation({ locationId, limit, offset }) {
    try {
      const { data } = await Vue.http.get(
        ENDPOINTS.GET_PROPERTIES_IN_LOCATION(locationId, limit, offset)
      );
      return data;
    } catch (err) {
      resolveRequestError(err, true, false);
    }
  }

  static async fetchPropertyById(propertyId) {
    try {
      const { data } = await Vue.http.get(
        ENDPOINTS.GET_PROPERTY_BY_ID(propertyId)
      );
      return data;
    } catch (err) {
      resolveRequestError(err, true, false);
    }
  }

  static async editPropertyById(propertyId, form) {
    try {
      const data = await Vue.http.patch(
        ENDPOINTS.EDIT_PROPERTY_BY_ID(propertyId),
        form
      );
      return data;
    } catch (err) {
      resolveRequestError(err, true, false);
    }
  }
}
