<template>
  <div :class="`input-box mb-4  ${focusClass} text-sm`">
    <div style="flex: 1">
      <div class="label font-weight-bold text-black mb-3" v-if="outlined">
        {{ label }}
      </div>
      <b-dropdown
        id="dropdown-left"
        :text="String(formText) ? String(formText) : placeholder || label"
        variant="white"
        :block="block"
        no-caret
        :toggle-class="`text-left px-3 ${
          selected ? 'text-black' : 'text-gray-700'
        }`"
        menu-class="w-100 shadow border border-gray-600 rounded"
        :class="`${
          !outlined ? 'border-top-0 border-left-0 border-right-0 rounded-0' : ''
        }  border mt-0 rounded ${focusClass} text-gray-800 input-box__dropdown bg-white`"
      >
        <b-dropdown-item
          class="py-0 mt-0 mb-0"
          @click="selected = option"
          v-for="(option, ix) in options"
          :key="ix"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="text-sm">
              {{ itemText ? option[itemText] : option }}
            </div>
            <div v-if="selected == option">
              <b-icon icon="check" scale="1.2"></b-icon>
            </div>
          </div>
        </b-dropdown-item>
        <template #button-content>
          <div class="d-flex justify-content-between">
            <div class="text-sm">
              {{ formText }}
            </div>
            <b-icon icon="chevron-down" scale="0.8" />
          </div>
        </template>
      </b-dropdown>
    </div>
    <div>
      <slot name="icon"></slot>
      <!-- <icon :name="(type ==='email' ? 'check-circle' : '') || (type === 'password' ? 'eye' : '')" size="1.2" :color="color" iconBlue /> -->
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "text",
      },
      color: {
        type: String,
        default: "grey",
      },
      value: {
        type: [String, Object],
        default: "",
      },
      "item-value": {
        type: String,
      },
      "item-text": {
        type: String,
      },
      placeholder: {
        type: String,
        default: "",
      },
      inverse: {
        type: Boolean,
        default: false,
      },
      outlined: {
        type: Boolean,
        default: false,
      },
      block: {
        type: Boolean,
        default: false,
      },
      "return-object": {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
      },
      pad: Boolean,
    },
    data() {
      return {
        formValue: this.value,
        formText: "",
        isFocused: false,
        selected: "",
      };
    },
    computed: {
      focusClass() {
        let result = `border-gray-700`;
        if (this.isFocused) result = `border-gray-800`;
        return result;
      },
      textClass() {
        let result = `text-gray-700`;
        if (this.isFocused) result = `text-gray-800`;
        return result;
      },
    },
    watch: {
      selected(v) {
        this.handleSelected(v);
      },
    },
    methods: {
      handleSelected(value) {
        //if you need a particular property in the item to only display e.g name property else you see the entire object
        if (this.itemText) {
          this.formText = value[this.itemText];
        } else {
          this.formText = value;
        }

        //return the selected item value, e.g. value of name property, but if returnObject is true, return the entire object
        if (this.itemValue) {
          if (this.returnObject) {
            return this.$emit("input", value);
          }
          this.$emit("input", value[this.itemValue]);
        } else {
          return this.$emit("input", value);
        }
      },
    },
    mounted() {
      if (this.value) {
        this.selected = this.value;
        this.formText = this.itemText
          ? this.formValue[this.itemText]
          : this.formValue;
        this.formValue = this.value;
        this.handleSelected(this.value);
      }
    },
  };
</script>
<style lang="scss" scoped>
  .i {
    position: relative;

    img {
      position: absolute;
      top: 65%;
      right: 20px;
    }
  }

  .input-box__dropdown .dropdown-menu {
    height: 9rem;
    max-height: 9rem;
    overflow-y: scroll;
    width: 100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  ::v-deep ul li::before {
    content: "";
    // color: transparent;
    font-weight: bold;
    display: inline-block;
    width: 1.4em;
    margin-left: -1.4em;
    vertical-align: middle;
  }
</style>
