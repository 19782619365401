import { PayoutService } from '../../services';

const state = {
    payouts: [],
    payout: [],
    count: 0,
}

const mutations = {
    setPayout( state, data){

    }
}

const actions = {
    async fetchPayouts(_, params) {
        let data = await PayoutService.fetchPayouts(params);
        return data
    },
    async createPayout(form) {
        let data = await PayoutService.createPayout(form);
        if (data) {
            commit('setPayout', data.payout || {})
        }
    },
    async approvePayout( {dispatch}, params){
        const { data } = await PayoutService.approvePayout(params);
        dispatch(
            'toast',
            {
                variant: 'success',
                message: data?.message || 'Payout Successful'
            },
            {root: true}
        )

        return data
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
  };