import PaymentService from '../../services/payments';

const state = {
  payments: [],
  payment: {},
  count: 0,
  pendingCount: 0,
};

const getters = {
  count(state) {
    return state.count;
  },
  pendingCount(state) {
    return state.pendingCount;
  },
  payment: ({ payment }) => payment,
};

const mutations = {
  setPayment(state, data) {
    state.payment = { ...data }
  },
};

const actions = {
  async fetchPayments(_, params) {
    let data = await PaymentService.fetchPayments(params);
    return data;
  },

  async fetchPendingPayments(_, params) {
    let data = await PaymentService.fetchPendingPayments(params);
    return data;
  },

  async fetchPaymentById({ commit }, id) {
    let data = await PaymentService.fetchPaymentById(id);
    if (data) {
      commit("setPayment", data.payload || {});
    }
    return data;
  },

  async verifyPayment({ dispatch }, form) {
    let data = await PaymentService.verifyPayment(form);
    dispatch(
      'toast',
      {
        variant: 'success',
        message: data?.message || 'Payment Verified Successfully',
      },
      { root: true }
    );

    return data;
  },

  async approvePayment({ dispatch }, form) {
    let data = await PaymentService.approvePayment(form);
    dispatch(
      'toast',
      {
        variant: 'success',
        message: data?.message || 'Payment Approved Successfully',
      },
      { root: true }
    );

    return data;
  },

  async declinePayment({ dispatch }, form) {
    const data = await PaymentService.declinePayment(form);
    dispatch(
      'toast',
      {
        variant: 'success',
        message: data?.message || 'Payment Disapproved Successfully',
      },
      { root: true },
    );

    return true;
  },

  async fetchPaymentCount({ state }, params) {
    let { data } = await PaymentService.fetchPaymentCount();
    state.count = data.payload?.count ?? 0;
    return data;
  },

  async fetchPendingPaymentCount({ state }, params) {
    let { data } = await PaymentService.fetchPendingPaymentCount();
    state.pendingCount = data.payload?.count ?? 0;
    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
