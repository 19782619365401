import Vue from "vue";
import { resolveRequestError } from "@/utils/errorsHandler";
import * as ENDPOINTS from "./endpoints";

export default class AuthService {
  static async login({ username, password }) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.LOGIN, {
        username,
        password,
      });

      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchUserProfile() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.PROFILE);

      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchUserLogs() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.LOGS);
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async resendVerificationEmail(credentials) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.RESEND_VERIFY_EMAIL,
        credentials
      );
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async forgotPassword(email) {
    try {
      const { data } = await Vue.http.post(ENDPOINTS.FORGOT_PASSWORD, {
        email,
      });
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async resetPassword({ password, token, uid }) {
    try {
      const { data } = await Vue.http.post(
        ENDPOINTS.RESET_PASSWORD(token, uid),
        {
          new_password: password,
        }
      );
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async changePin({ old_pin, new_pin, confirm_pin}) {
    try {
      const { data } = await Vue.http.patch(
        ENDPOINTS.CHANGE_PIN,
        {
          old_pin,
          new_pin,
          confirm_pin,
        }
      );
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async verifyEmail(params) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.VERIFY_EMAIL(params));
      return data;
    } catch (err) {
      resolveRequestError(err, true, false);
    }
  }

  static authenticateUser(tokens) {
    Vue.http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${tokens.access}`;
    localStorage.setItem("authToken", tokens.access);
    localStorage.setItem("refreshToken", tokens.refresh);
  }
}
