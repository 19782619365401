import Vue from "vue";
import { resolveRequestError } from "@/utils/errorsHandler";
import * as ENDPOINTS from "./endpoints";

export default class RealtorsService {
  static async fetchRealtors(params) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.REALTORS, { params });
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchRealtorsById(id) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.GET_REALTORS_BY_ID(id));
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchRealtorLevel() {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.REALTOR_LEVEL);
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async updateRealtorLevel(payload) {
    try {
      const { data } = await Vue.http.patch(
        ENDPOINTS.CHANGE_REALTOR_LEVEL,
        payload
      );
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }
}
