import SalesService from "../../services/sales";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchSales(_, params) {
    const { payload } = await SalesService.fetchSales(params);
    return payload;
  },

  async createSubscriptionPayment(_, data) {
    const { payload } = await SalesService.createSubscriptionPayment(data);
    return payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
