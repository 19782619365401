<template>
  <div class="page-wrapper bg-background">
    <div class="w-100">
      <div class="position-fixed" :style="{ left: 0 }">
        <rm-sidebar />
      </div>
      <div class="page-container">
        <div class="w-100">
          <b-container fluid class="py-5">
            <b-row align-h="center">
              <b-col cols="12">
                <div class="d-flex justify-content-between align-items-center">
                  <div
                    class="text-lg-2 font-weight-bolder text-primary text-capitalize"
                  >
                    {{ $route.meta.title }}
                  </div>
                  <b-dropdown
                    toggle-class="bg-transparent border-0"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <div class="d-flex align-items-center">
                        <div
                          class="profile-image__card rounded-circle"
                          :style="{
                            backgroundImage: `url(${userObj.picture})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                          }"
                        ></div>
                        <div class="ml-4 mr-2 text-black">
                          {{ user.full_name }}
                        </div>
                        <b-icon icon="chevron-down" scale="0.8"></b-icon>
                      </div>
                    </template>
                    <b-dropdown-item @click="logout">Logout</b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-col>
              <b-col>
                <slot />
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['userObj']),
    user() {
      return this.userObj ?? {};
    },
  },
  methods: {
    logout() {
      localStorage.clear();
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  height: 100vh;
  width: 100vw;
  overflow: auto;

  .page-container {
    margin-left: 18.7rem;

    padding: 0 3vw;

    width: calc(100% - 18.7rem) !important;

    @media screen and (max-width: 1440px) {
      padding: 0 !important;
    }
  }
}

.profile {
  &-image__card {
    height: 3.5rem;
    width: 3.5rem;
    background-color: $primary-color;
  }
}

.bg-background {
  background-color: #e5e5e5;
}
</style>
