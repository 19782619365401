import Vue from "vue";
import { resolveRequestError } from "@/utils/errorsHandler";
import * as ENDPOINTS from "./endpoints";

export default class CommissionService {
  static async fetchCommissions(params) {
    try {
      const { data } = await Vue.http.get(
        ENDPOINTS.COMMISSIONS,
        { params },
      );
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchCommissionById(id) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.GET_COMMISSIONS_BY_ID(id));

      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }
};

