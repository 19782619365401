<template>
  <div class="input-box mb-4 text-sm">
    <div style="flex: 1">
      <div class="label text-dark mb-2" v-if="outlined">
        {{ label }}
      </div>
      <b-form-textarea
        :placeholder="!outlined ? label : placeholder"
        :class="`border ${
          !outlined
            ? 'border-top-0 border-left-0 border-right-0 rounded-0'
            : 'py-md-4 px-md-4 mt-md-3 rounded-md'
        } mt-0 py-0 ${focusClass} text-gray-800 bg-transparent`"
        :id="`type-${type}`"
        @focus="isFocused = true"
        @blur="isFocused = false"
        v-model="formValue"
        :rows="rows"
        v-on="$listeners"
      />
      <slot name="errors"></slot>
    </div>
    <div>
      <slot name="icon"></slot>
      <!-- <icon :name="(type ==='email' ? 'check-circle' : '') || (type === 'password' ? 'eye' : '')" size="1.2" :color="color" iconBlue /> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    color: {
      type: String,
      default: 'grey',
    },
    value: {
      type: String,
      default: '',
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      formValue: this.value,
      isFocused: false,
    };
  },
  computed: {
    focusClass() {
      let result = `border-gray-700`;
      if (this.isFocused) result = `border-gray-800`;
      return result;
    },
    textClass() {
      let result = `text-gray-700`;
      if (this.isFocused) result = `text-gray-800`;
      return result;
    },
  },
  watch: {
    formValue(v) {
      this.$emit('input', v);
      this.formValue = v;
    },
    value(v) {
      this.formValue = v;
    },
  },
};
</script>

<style lang="scss" scoped>
//for some reason, this class isn't being recognised from _helpers.scss until declared in the local file
.rounded-md {
  border-radius: $border-radius-md;
}
</style>
