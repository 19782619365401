<template>
  <div class="btn-wrapper">
    <button
      ref="baseButton"
      class="py-2 btn px-4 rounded"
      :class="{
        'bg-transparent outline': outline,
        'btn-lg': large,
        'btn-sm': small,
        'btn-md': medium,
        'btn-block': full,
        'btn-shadow': bgShadow,
        'py-2 btn-pad': pad,
        [`bg-${bgColor}`]: bgColor,
        [`text-${textColor}`]: textColor,
        'btn-pad': pad,
      }"
      :disabled="disableButton"
      v-on="$listeners"
    >
      <div
        :class="{ 'text-sm': true }"
        class="d-flex align-items-center justify-content-center text-capitalize"
      >
        <slot /><slot name="preppend-icon" /> {{ text }}
        <slot name="post-icon" />

        <b-spinner
          v-if="loading"
          class="ml-3"
          small
          variant="light"
        ></b-spinner>
      </div>
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: true,
      },
      variant: {
        type: String,
      },
      bgColor: {
        type: String,
        default: "primary--gradient",
      },
      textColor: {
        type: String,
        default: "white",
      },
      loading: Boolean,
      preppendIconInner: {
        type: String,
      },
      appendIconInner: {
        type: String,
      },
      hasRightIcon: {
        type: Boolean,
        default: false,
      },
      large: Boolean,
      small: Boolean,
      block: Boolean,
      medium: Boolean,
      outline: Boolean,
      full: Boolean,
      disableButton: Boolean,
      pad: Boolean,
      bgShadow: Boolean,
    },
    mounted() {
      this.applyVariant();
    },

    methods: {
      applyVariant() {
        if (this.outline) {
          this.$refs.baseButton.classList.add(this.variant);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  button {
    border-radius: 5rem;
    outline: none;
    font-size: 1rem;
  }
  .btn {
    &-lg {
      min-width: 19rem;
    }
    &-pad {
      min-width: 17rem;
    }
    &-md {
      min-width: 12rem;
    }
    &-sm {
      min-width: 7rem;
    }
    &-shadow {
      box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
    }
  }

  .outline {
    border: 2px solid;
  }

  .primary {
    border-color: var(--primary);
    color: var(--primary);
  }

  .action-verify {
    background: #f0eaf5;
    color: #6a2a9d;
  }
  .secondary {
    border-color: var(--secondary);
    color: var(--secondary);
  }

  .danger {
    border-color: var(--danger);
    color: var(--danger);
  }

  .block-primary {
    color: #fff;
    background: var(--primary--gradient);
  }

  .block-danger {
    color: #fff;
    background: var(--danger--gradient) !important;
  }
</style>
