import Vue from "vue";
import VueRouter from "vue-router";
import { isUserLoggedIn } from "@/utils/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/clients",
    component: () => import("@/views/auth/index.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("../views/auth/login/index.vue"),
        meta: {
          title: "Login",
          layout: "public",
          requiresAuth: false,
        },
      },
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/dashboard/index.vue"),
    meta: {
      title: "dashboard",
      layout: "private",
      requiresAuth: true,
    },
  },
  {
    path: "/clients",
    name: "clients",
    component: () => import("../views/clients/index.vue"),
    meta: {
      title: "clients",
      requiresAuth: true,
      layout: "private",
    },
  },
  {
    path: "/clients/:id",
    name: "clients.profile",
    component: () => import("../views/clients/ClientProfile.vue"),
    meta: {
      title: "Client's Profile",
      requiresAuth: true,
      layout: "private",
    },
  },
  {
    path: "/payouts",
    name: "payouts",
    component: () => import("../views/payout/index.vue"),
    meta: {
      title: "payout",
      requiresAuth: true,
      layout: "private",
    },
  },
  {
    path: "/realtors",
    name: "realtors",
    component: () => import("../views/realtors/index.vue"),
    meta: {
      title: "realtors",
      requiresAuth: true,
      layout: "private",
    },
  },
  {
    path: "/locations",
    name: "locations",
    component: () => import("../views/locations/index.vue"),
    meta: {
      title: "locations",
      requiresAuth: true,
      layout: "private",
    },
  },
  {
    path: "/properties",
    name: "properties",
    component: () => import("../views/one-properties/index.vue"),
    meta: {
      title: "properties",
      requiresAuth: true,
      layout: "private",
    },
  },
  {
    path: "/properties/:propertyId",
    name: "properties.details",
    component: () => import("../views/one-properties/PropertyDetails.vue"),
    meta: {
      title: "Property Details",
      requiresAuth: true,
      layout: "private",
    },
  },

  // {
  //   path: "/locations/:locationId/properties",
  //   name: "properties",
  //   component: () => import("../views/properties/index.vue"),
  //   meta: {
  //     title: "Properties",
  //     requiresAuth: true,
  //     layout: "private",
  //   },
  // },
  // {
  //   path: "/locations/:locationId/properties/:propertyId",
  //   name: "properties.details",
  //   component: () => import("../views/properties/PropertyDetails.vue"),
  //   meta: {
  //     title: "Property Details",
  //     requiresAuth: true,
  //     layout: "private",
  //   },
  // },
  {
    path: "/pending-allocations",
    name: "pending-allocations",
    component: () => import("../views/pending-allocations/index.vue"),
    meta: {
      title: "Allocations",
      requiresAuth: true,
      layout: "private",
    },
  },
  {
    path: "/training",
    component: () => import("../views/training/index.vue"),
    children: [
      {
        path: "",
        name: "training",
        component: () => import("../views/training/Trainings.vue"),
        meta: {
          title: "training",
          layout: "private",
          requiresAuth: true,
        },
      },
      {
        path: "new",
        name: "training.new",
        component: () => import("../views/training/CreateTraining.vue"),
        meta: {
          title: "Create Training",
          layout: "private",
          requiresAuth: true,
        },
      },
      {
        path: ":id",
        name: "training.details",
        component: () => import("../views/training/TrainingDetails.vue"),
        meta: {
          title: "Training Details",
          layout: "private",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/sales",
    name: "sales",
    component: () => import("../views/sales/index.vue"),
    meta: {
      title: "sales",
      layout: "private",
      requiresAuth: true,
    },
  },
  {
    path: "/sales/:salesID",
    name: "sales.details",
    component: () => import("../views/sales/salesDetails.vue"),
    meta: {
      title: "Sales Details",
      layout: "private",
      requiresAuth: true,
    },
  },
  {
    path: "/promotions",
    name: "promotions",
    component: () => import("../views/promotions/index.vue"),
    meta: {
      title: "promotions",
      layout: "private",
      requiresAuth: true,
    },
  },
  {
    path: "/payments",
    name: "payments",
    component: () => import("../views/payments/index.vue"),
    meta: {
      title: "payments",
      layout: "private",
      requiresAuth: true,
    },
  },
  {
    path: "/payments/pending",
    name: "payments.pending",
    component: () => import("../views/payments/pending-payments.vue"),
    meta: {
      title: "unapproved payments",
      layout: "private",
      requiresAuth: true,
    },
  },
  {
    path: "/commissions",
    name: "commissions",
    component: () => import("../views/commissions/index.vue"),
    meta: {
      title: "commissions",
      requiresAuth: true,
      layout: "private",
    },
  },
  {
    path: "/withholding-tax",
    name: "withholding-tax",
    component: () => import("../views/withholding-tax/index.vue"),
    meta: {
      title: "withholding-tax",
      requiresAuth: true,
      layout: "private",
    },
  },
  {
    path: "/discount-vouchers",
    name: "discount-vouchers",
    component: () => import("../views/discount-vouchers/index.vue"),
    meta: {
      title: "discount-vouchers",
      requiresAuth: true,
      layout: "private",
    },
  },
  {
    path: "/all-requests",
    name: "all-requests",
    component: () => import("../views/all-requests/index.vue"),
    meta: {
      title: "All Requests",
      layout: "private",
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/settings/index.vue"),
    meta: {
      title: "settings",
      requiresAuth: true,
      layout: "private",
    },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("../views/change-password/index.vue"),
    meta: {
      title: "Change Password",
      requiresAuth: true,
      layout: "private",
    }
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../views/users/index.vue"),
    meta: {
      title: "users",
      requiresAuth: true,
      layout: "private",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Route Guards before every navigation
router.beforeEach((to, _, next) => {
  const { requiresAuth } = to.meta;
  if (requiresAuth === true && !isUserLoggedIn()) {
    next(`/login?next=${to.path}`);
  } else if (requiresAuth === false && isUserLoggedIn()) {
    next({
      name: "dashboard",
    });
  } else {
    next();
  }
});

router.afterEach((to) => {
  document.title = `${to.meta.title || "OneApp"} | OneApp Admin`;
});

export default router;
