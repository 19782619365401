import RealtorsService from "../../services/realtors";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchRealtors(_, params) {
    const { payload } = await RealtorsService.fetchRealtors(params);
    return payload;
  },

  async fetchRealtorLevel() {
    const { payload } = await RealtorsService.fetchRealtorLevel();
    return payload;
  },

  async updateRealtorLevel({}, payload) {
    const { message } = await RealtorsService.updateRealtorLevel(payload);
    return message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
