<template>
  <div
    class="d-flex justify-content-between align-items-center rounded-sm bg-white p-4"
  >
    <div>
      <div class="d-flex align-content-between">
        <div class="text-black text-lg-2 font-weight-bold">
          {{ metric.amount }}
        </div>
        <div
          v-show="growth"
          class="text-sm-2 ml-3"
          :class="{
            'text-secondary': metric.growth > 0,
            'text-danger': metric.growth < 0,
          }"
        >
          {{ `${metric.growth > 0 ? "+" : ""}${metric.growth}` }}%
        </div>
      </div>
      <div>{{ metric.name }}</div>
    </div>
    <div>
      <rm-base-icon :name="metric.icon" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    metric: {
      type: Object,
      required: true,
    },
    growth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
