import { CommissionService } from "../../services";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchCommissions({}, params) {
    const { payload } = await CommissionService.fetchCommissions(params);
    return payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
