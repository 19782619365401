// Auth
export const LOGIN = `auth/login/admin`;
export const REGISTER_CLIENTS = `/user/register/`;
export const REGISTER_REALTORS = `/realtors/`;
export const FORGOT_PASSWORD = `/user/reset-password/`;
export const CHANGE_PIN = `/user/pin/`;
export const RESET_PASSWORD = (token, uid) =>
  `${FORGOT_PASSWORD}?uidb64=${uid}&token=${token}`;
export const RESEND_EMAIL_VERIFICATION = `/resend-email-verification/`;
export const VERIFY_EMAIL = (data) =>
  `/user/verify-email?uidb64=${data.uid}&token=${data.token}`;
export const RESEND_VERIFY_EMAIL = `/user/verify-email`;

// Clients
export const CLIENTS = `/admin/users/`;
export const GET_USER_BY_ID = (id) => `/admin/users/${id}/`;
export const CREATE_CLIENTS = `/admin/users/create/`;

// Profile
export const LOGS = `/logs/`;
export const PROFILE = `admin/profile`;
export const UPLOAD_GOVERNMENT_ID = `${PROFILE}government/upload/`;

// Wallet
export const CREATE_VBA = `/wallet-virtualbankaccount/`;
export const GET_WALLET_DATA = `/wallet-transactions/`;
export const GET_WALLET_HISTORY = `admin/wallet-txtn`;

// properties
export const FETCH_PROPERTIES = (page = 1, limit, offset) =>
  `/admin/products?page=${page}&limit=${limit}&offset=${offset}`;

// Documents
export const GET_DOCUMENTS = (limit, offset) =>
  `/documents/?limit=${limit}&offset=${offset}`;

// Catalogue
export const CATALOGUE = `/admin/catalogue`;

//Admin
export const CREATE_ADMIN = `/admin/users/create/admin/`;

// Locations
export const LOCATIONS = `admin/locations`;
export const CREATE_LOCATION = `/admin/locations`;
export const GET_LOCATIONS = (limit, page) =>
  `/${LOCATIONS}?limit=${limit}&page=${page}`;
export const GET_LOCATION_BY_ID = (id) => `${LOCATIONS}/${id}`;
export const GET_LOCATION_LIST = `/location/list`;

// Properties
export const CREATE_PROPERTY = `/admin/products`;
export const GET_PRODUCT_TYPES = `/product-types`;
export const GET_PROPERTY_BY_ID = (propertyId) => `/products/${propertyId}`;
export const GET_PROPERTIES_IN_LOCATION = (locationId, limit, offset) =>
  `${GET_LOCATION_BY_ID(
    locationId
  )}/properties?limit=${limit}&offset=${offset}`;
// export const GET_PLOT_TYPES = () => `/plot-types/`
export const EDIT_PROPERTY_BY_ID = (id) => `/admin/products/${id}`;

// Sales
export const SALES = "/admin/sales";
export const GET_SALES_BY_ID = (id) => `/sales/${id}`;
export const CREATE_SUBSCRIPTION_PAYMENT = "/admin/payments/subscription";
// export const GET_SALES = `${CATALOGUE}/sales/all/`;
// export const GET_PAYMENT_EVENTS = `/admin/payment/purchases/`;

// Payout
export const PAYOUT = "/payouts";
export const APPROVE_PAYOUTS = "/admin/payouts/approve/bulk"

//realtors
export const REALTORS = "/admin/realtors";
export const GET_REALTORS_BY_ID = (id) => `/realtors/${id}`;
export const CHANGE_REALTOR_LEVEL = "/admin/realtor/realtor-level"

export const REALTOR_LEVEL = "/admin/realtor-levels";
export const GET_REALTOR_LEVEL_BY_ID = (id) => `/realtor-levels/${id}`;

//Customers
export const CUSTOMERS = "/admin/customers";
export const GET_CUSTOMERS_BY_ID = (id) => `/customers/${id}`;

// Purchases
export const PURCHASES = `${CATALOGUE}/purchases`;
export const GET_PURCHASE = (purchaseId) => `${PURCHASES}/${purchaseId}`;
export const GET_PURCHASES = (limit, offset) =>
  `${PURCHASES}/?limit=${limit}&offset=${offset}`;

// commission
export const COMMISSIONS = "/admin/commissions";
export const GET_COMMISSIONS_BY_ID = (id) => `/commissions/${id}`;

// Requests
export const GET_REQUESTS = `${CATALOGUE}/requests/`;
export const GET_REQUESTS_TYPE = `${CATALOGUE}/requesttypes/`;

// Learning
export const GET_TRAINING = (id) => `/trainings/${id}/`;
export const GET_TRAININGS = (limit, offset) =>
  `/trainings/?limit=${limit}&offset=${offset}`;

// Payments
export const PAYMENTS = `/admin/payments`;
export const PENDING_PAYMENTS = `${PAYMENTS}/pending`
export const GET_PAYMENT = (id) => `${PAYMENTS}/${id}/`;

export const VERIFY_PAYMENT = `${PAYMENTS}/verify`;
export const APPROVE_PAYMENT = `${PAYMENTS}/approve`;
export const DECLINE_PAYMENT = `${PAYMENTS}/reject`;
export const GET_PAYMENT_COUNT = `${PAYMENTS}/count`;
export const GET_PENDING_PAYMENT_COUNT = `${PAYMENTS}/pending/count`;
