function isUserLoggedIn() {
  try {
    const status = localStorage.getItem("authToken");
    if (status) {
      return true;
    }
  } catch (error) {
    return false;
  }
}

export { isUserLoggedIn };
