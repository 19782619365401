<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  // mounted() {
  // 	this.globalRouter("dashboard");
  // },
};
</script>
