<template>
  <div class="rounded-sm bg-white p-4 metric-card" :class="metric.route ? 'pointer' : ''" @click="routeTo(metric.route)">
    <!-- <div class="metric_name">{{ metric.name }}</div>
    <div class="d-flex align-items-center mt-2">
      <div class="mr-auto">
        <div class="d-flex align-content-between">
          <div class="text-black text-lg-2 font-weight-bold">
            {{ metric.amount }}
          </div> -->
          <!-- <div
            class="text-sm-2 ml-3"
            :class="{
              'text-secondary': metric.growth > 0,
              'text-danger': metric.growth < 0,
            }"
          >
            {{ `${metric.growth > 0 ? "+" : ""}${metric.growth}` }}%
          </div> -->

        <!-- </div>
      </div>
      <div>
        <rm-base-icon :name="metric.icon" />
      </div>
    </div> -->





    <div class="metric_name d-flex v align-items-center justify-content-between mt-2 ml-2">{{ metric.name }}
      <div>
        <rm-base-icon :name="metric.icon" />
      </div>
    </div>
      <div class="text-black text-lg-2 font-weight-bold ml-2">
        {{ metric.amount }}
      </div>
  </div>
</template>

<script>
export default {
  props: {
    metric: {
      type: Object,
      required: true,
    },
  },
  methods: {
    routeTo(route) {
      if (route) {
        this.$router.push({ name: route });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.metric-card {
  min-height: 10rem;
}
</style>
