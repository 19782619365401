import { RequestsService } from "../../services";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchRequests() {
    let { data } = await RequestsService.fetchRequests();

    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
