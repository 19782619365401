import app from '@/main';

function errorHandler(e) {
  let data = 'Connection seems to be very slow';
  if (e.response && e.response.status === 400) {
    data = e.response.data;
  } else if (e.response && e.response.status === 401) {
    data = 'You are not logged in';
    localStorage.clear();
    if (document.location.pathname == '/login') {
      data = e.response.data;
      // app.$router.replace({
      //   name: "login"
      // });
    } else {
      app.$router.replace({
        path: `/login?next=${document.location.pathname}`,
      });
    }
  // } else if (e.response && e.response.status === 403) {
  //   data = e.response.data;
  //   app.$store.dispatch('toast', {
  //     message: data.messag,
  //     variant: 'warning',
  //   });
  //   app.$store.dispatch('user/updateProfile', data.user);
  //   app.$router.replace({
  //     name: 'onboarding',
  //   });
  } else if (e.response && e.response.status === 404) {
    data = 'Oops! an error occured ';
  } else if (e.response && e.response.status === 500) {
    data = 'Oops! an error occured';
  }
  const status = e.response ? e.response.status : 0;
  return {
    status,
    data,
  };
}

/**
 * Handles how error is displayed to the user
 * @param {Error} error object
 * @param {Boolean} throwAll
 * @param {Boolean} popToast
 */
function resolveRequestError(err, throwAll = true, popToast = true) {
  // app.$Progress.fail();

  const { status, data } = errorHandler(err);
  // if form validation errors are present, throw it so the caller can catch it
  // if (data.data) throw data.data;

  // if ([401, 403].includes(status)) {
  //   return status;
  // }

  let text = data.error && data.error[0].message;

  if (!text) text = data;
  // if (popToast) console.log(text);
  app.$store.dispatch('toast', {
    variant: 'error',
    message:
      typeof text == 'object' || text == undefined
        ? 'Something went wrong, Please try again'
        : text,
  });

  if (status === 0 && !popToast)
    app.$store.dispatch('toast', {
      variant: 'error',
      message: text,
    });

  if (throwAll) throw data;
}

function isUserLoggedIn() {
  try {
    const status = atob(localStorage.getItem('UserStatus'));
    return status != null && status === 'true';
  } catch (error) {
    return false;
  }
}

export { resolveRequestError, isUserLoggedIn };
