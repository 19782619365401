import Vue from 'vue';
import { resolveRequestError } from '@/utils/errorsHandler';
import * as ENDPOINTS from './endpoints';

export default class ClientsService {
  static async fetchCustomers(params) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.CUSTOMERS, { params });
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchCustomersById(id) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.GET_CUSTOMERS_BY_ID(id));

      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  static async fetchUserById(id) {
    try {
      const { data } = await Vue.http.get(ENDPOINTS.GET_USER_BY_ID(id));
      return data;
    } catch (err) {
      resolveRequestError(err);
    }
  }

  // static async createNewUser(credentials) {
  //   try {
  //     const { data } = await Vue.http.post(
  //       ENDPOINTS.CREATE_CLIENTS,
  //       credentials
  //     );

  //     return data;
  //   } catch (err) {
  //     resolveRequestError(err);
  //   }
  // }
}
