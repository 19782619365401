import { AuthService } from '../../services';

const state = {
  userObj: JSON.parse(localStorage.getItem('user')) || {},
};

const getters = {
  userObj: ({userObj}) => userObj
};

const mutations = {
  storeUser(state, data) {
    state.userObj = data;
    // console.log(data);
    localStorage.setItem('user', JSON.stringify(data));
  },
};

const actions = {
  async login({ dispatch }, form) {
    let { username, password } = form;

    const { payload, statusCode } = await AuthService.login({
      username,
      password,
    });

    AuthService.authenticateUser({
      access: payload.tokens.access_token,
      refresh: payload.tokens.refresh_token,
    });

    await dispatch('fetchProfile');

    return statusCode;
  },

  async verifyEmail({ dispatch }, form) {
    await AuthService.verifyEmail(form);
    dispatch(
      'toast',
      { variant: 'success', message: 'Email verified successfully' },
      { root: true }
    );
  },

  async forgotPassword({ dispatch }, email) {
    let { message, status } = await AuthService.forgotPassword(email);

    if (status) {
      dispatch('toast', { variant: 'success', message }, { root: true });
    }

    return { status };
  },

  async resetPassword({ dispatch }, form) {
    await AuthService.resetPassword({
      password: form.password,
      token: form.token,
      uid: form.uid,
    });

    dispatch(
      'toast',
      { variant: 'success', message: 'Pasword Reset Successfully' },
      { root: true }
    );
  },

  async changePin({dispatch}, form) {
    await AuthService.changePin({
      old_pin: form.old_password,
      new_pin: form.new_password,
      confirm_pin: form.confirm_password,
    })
    dispatch(
      'toast',
      { variant:'success', message: 'Password Changed Successfully' },
      { root: true }
    );
  },

  async updateUser({ commit }, userData) {
    commit('storeUser', userData);
  },

  async fetchProfile({ commit }) {
    let { payload } = await AuthService.fetchUserProfile();

    commit('storeUser', payload);
  },

  async fetchLogs() {
    let { data } = await AuthService.fetchUserLogs();

    return data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
