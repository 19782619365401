export default {
  install(Vue) {
    Vue.prototype.$formatCurrency = (amount) => {
      return Intl.NumberFormat().format(amount);
    };

    Vue.prototype.$formatAsMoney = (amount) => {
      return Intl.NumberFormat().format(amount);
    };

    Vue.prototype.$convertToCurrency = (amount) => {
      return Intl.NumberFormat().format(amount / 100);
    };
  },
};
