import { AdminService } from "../../services";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async createAdmin({ dispatch }, form) {
    let { data } = await AdminService.createAdmin(form);
    dispatch(
      "toast",
      { variant: "success", message: "Admin created successfully" },
      { root: true }
    );

    return data;
  },

  async fetchAllAdmin() {
    let { data } = await AdminService.fetchAllAdmin();

    return data;
  },

  async fetchWalletHistory({}, params) {
    const { payload } = await AdminService.fetchWalletHistory(params);

    return payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
